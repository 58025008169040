import styled, { css } from "styled-components";
import { TextNormal } from "../../../../styles/BasicStyles";
import { mediaDown } from "../../../../styles/Mixins";
import ThemeStyle from "../../../../styles/ThemeStyle";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem 0 2rem 2rem;

  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      padding: 1rem 0 0.5rem 1.5rem;
    `
  )}

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 1rem 0 1rem 1rem;
    `
  )}
`;

export const RecipeIcon = styled.div``;

export const Image = styled.div`
  min-width: 7rem;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      min-width: 5rem;
      min-height: 5rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      min-width: 4rem;
      min-height: 4rem;
    `
  )}
`;

export const Text = styled.h4`
  ${TextNormal};
  font-size: 1.8rem;
  padding-left: 3rem;
  padding-right: 1rem;

  ${mediaDown(
    "2600px",
    css`
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 1.6rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-left: 1rem;
      font-size: 1.8rem;
    `
  )}
`;
