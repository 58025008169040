import styled, { css } from "styled-components";
import ThemeStyle from "../../../../styles/ThemeStyle";
import { mediaDown } from "../../../../styles/Mixins";
import { TextLarge, TextNormal } from "../../../../styles/BasicStyles";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 3fr;
  width: calc(100% - 1.6rem);
  height: 100%;
  font-size: 2.2rem;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 1fr;
      grid-template-rows: 25rem auto;
      font-size: 1.8rem;
      padding-right: 2rem;
    `
  )}
`;

export const LeftColumn = styled.div`
  height: 100%;
  .recipe-image,
  img {
    width: 100%;
    height: 100%;
    object-position: center;
  }

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      .recipe-image,
      img {
        object-position: bottom;
      }
    `
  )}
`;

export const RightColumn = styled.div`
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
`;

export const Recipe = styled.div`
  margin-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      padding-left: 2rem;
      padding-right: 2rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    `
  )}
`;

export const Description = styled.p`
  ${TextNormal};
  width: calc(100% - 6rem);
  padding-top: 2rem;
  margin: 2rem auto 4rem auto;
  font-size: 1.8rem;
  border-top: 0.1rem solid ${ThemeStyle.colors.green};
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      margin: 2rem auto 3rem auto;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      width: calc(100% - 4rem);
    `
  )}
`;

export const Title = styled.h3`
  ${TextLarge};
  color: white;
  font-size: 1.3em;
`;

export const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 6.5rem;
  background-color: #305c41;
  display: flex;
  align-items: center;
  padding-left: 2rem;

  svg {
    position: absolute;
    height: 100%;
    right: 0;
    transform: translateX(96.5%);
  }
`;
