import React, { FunctionComponent } from "react";
import * as Styled from "./Slider.styled";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/grid";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderElement from "./SliderElement/SliderElement";
import { RecipeType } from "../../../@types/RecipeType";

type Props = {};

const Slider: FunctionComponent<Props> = () => {
  const drinks: RecipeType[] = [
    {
      title: "Honey & Tonic",
      recipes: [
        {
          line: "40\u00A0ml Tullamore D.E.W Honey",
          img: "CocktailRecipesSection/Slider/Recipes/bottle.png",
        },
        {
          line: "120\u00A0ml toniku",
          img: "CocktailRecipesSection/Slider/Recipes/tonic.png",
        },
        {
          line: "Plasterek cytryny",
          img: "CocktailRecipesSection/Slider/Recipes/lemon.png",
        },
      ],
      image: "CocktailRecipesSection/Slider/tonic.png",
      description:
        "Do szklanki z\u00A0lodem wlej 40\u00A0ml Tullamore D.E.W. Honey i\u00A0uzupełnij tonikiem. Serwuj z\u00A0plasterkiem cytryny.",
    },
    {
      title: "Tea & Honey",
      recipes: [
        {
          line: "40\u00A0ml Tullamore D.E.W Honey",
          img: "CocktailRecipesSection/Slider/Recipes/bottle.png",
        },
        {
          line: "15\u00A0ml soku z\u00A0cytryny i\u00A0plasterek do dekoracji",
          img: "CocktailRecipesSection/Slider/Recipes/citron.png",
        },
        {
          line: "5\u00A0ml syropu cukrowego",
          img: "CocktailRecipesSection/Slider/Recipes/syrop.png",
        },
        {
          line: "80\u00A0ml słodzonej herbaty Earl Grey",
          img: "CocktailRecipesSection/Slider/Recipes/tea.png",
        },
      ],
      image: "CocktailRecipesSection/Slider/toddy.png",
      description:
        "Do szklanki z\u00A0lodem wlej 40\u00A0ml Tullamore D.E.W. Honey, sok z\u00A0cytryny i\u00A0syrop cukrowy. Dopełnij ostudzoną herbatą i\u00A0zamieszaj. Serwuj z\u00A0plasterkiem cytryny.",
    },
    {
      title: "Honey & Ginger",
      recipes: [
        {
          line: "40\u00A0ml Tullamore D.E.W Honey",
          img: "CocktailRecipesSection/Slider/Recipes/bottle.png",
        },
        {
          line: "120\u00A0ml Ginger Beer",
          img: "CocktailRecipesSection/Slider/Recipes/ginger-beer.png",
        },
        {
          line: "Plasterek cytryny",
          img: "CocktailRecipesSection/Slider/Recipes/lemon.png",
        },
      ],
      image: "CocktailRecipesSection/Slider/tonic.png",
      description:
        "Do szklanki z\u00A0lodem wlej 40\u00A0ml Tullamore D.E.W. Honey, uzupełnij Ginger BEEr'em. Serwuj z\u00A0plasterkiem cytryny.",
    },
    {
      title: "Honey & Coffee",
      recipes: [
        {
          line: "40\u00A0ml Tullamore D.E.W Honey",
          img: "CocktailRecipesSection/Slider/Recipes/bottle.png",
        },
        {
          line: "100\u00A0ml świeżo parzonej czarnej kawy",
          img: "CocktailRecipesSection/Slider/Recipes/espresso.png",
        },
      ],
      image: "CocktailRecipesSection/Slider/coffee.png",
      description:
        "40\u00A0ml Tullamore D.E.W. Honey dodaj do czarnej kawy i\u00A0delikatnie wymieszaj. Serwuj w\u00A0ulubionym kubku.",
    },
  ];

  return (
    <Styled.Container>
      <Swiper
        loop={false}
        watchSlidesProgress={true}
        breakpoints={{
          2600: {
            slidesPerView: 4,
            initialSlide: 0,
            spaceBetween: 50,
          },
          1600: {
            slidesPerView: 2,
            initialSlide: 0,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 2,
            initialSlide: 0,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 1,
            initialSlide: 0,
            spaceBetween: 40,
          },
          576: {
            slidesPerView: 1,
            initialSlide: 0,
            spaceBetween: 20,
          },
        }}
      >
        {drinks.map((drink, index) => (
          <SwiperSlide key={index}>
            <SliderElement drink={drink} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Styled.Container>
  );
};

export default Slider;
