import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { mediaDown } from "../../styles/Mixins";
import {
  AppContainer,
  TitleColored,
  TextLarge,
  TextNormal,
} from "../../styles/BasicStyles";
import Background from "../../images/Common/bg-dark.jpg";
import BeeLargeGif from "../../images/Common/bee-large.gif";
import BeeLargeGifMobile from "../../images/Common/bee-large-mobile.gif";
import BeeLargePathSvg from "../../images/Common/bee-large-path.svg";
import BeeSmallGif from "../../images/Common/bee-small.gif";
import BeeSmallPathSvg from "../../images/Common/bee-small-path.svg";

export const RulesSection = styled.section`
  width: 100%;
  background: url("${Background}") center/cover no-repeat;
  overflow: hidden;
`;

export const Container = styled.div`
  ${AppContainer()};
  position: relative;
  padding: 8rem 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const TitleYellow = styled.h2`
  ${TitleColored("yellow")};
  margin-bottom: 4rem;
  transform: translateY(10rem);
  opacity: 0;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      margin-bottom: 2rem;
    `
  )};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transform: translateY(10rem);
  opacity: 0;
`;

export const TitleWhite = styled.h3`
  ${TitleColored("white")};
  margin: 4rem 0;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      margin: 2rem 0;
    `
  )};
`;

export const Subtitle = styled.h4`
  ${TextLarge};
  max-width: 49rem;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 4rem;
`;

export const SubtitleSmall = styled.h5`
  ${TextNormal};
  margin: 2rem 0;
  text-align: center;
  max-width: 44rem;
`;
export const BeeLargeWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 5%;
  display: flex;
  flex-flow: column;
  align-items: center;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      left: 0;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      bottom: 0;
    `
  )}
`;

export const BeeLarge = styled.div`
  transform: translate(-100rem, 30rem);
  background: url(${BeeLargeGif}) center/contain no-repeat;
  width: 40rem;
  height: 40rem;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      width: 30rem;
      height: 30rem;
    `
  )}

  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      width: 20rem;
      height: 20rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      background: url(${BeeLargeGifMobile}) center/contain no-repeat;
      width: 10rem;
      height: 10rem;
    `
  )}
`;

export const BeeLargePath = styled.div`
  background: url(${BeeLargePathSvg}) center/contain no-repeat;
  width: 46rem;
  height: 25rem;
  opacity: 0;
  transform: translateX(-33%);
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      display: none;
    `
  )}
`;

export const BeeSmallWrapper = styled.div`
  position: absolute;
  top: 10%;
  right: 0;
  ${mediaDown(
    ThemeStyle.breakpoints.xl,
    css`
      right: -10%;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      right: -25%;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      top: 15%;
      right: -40%;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      display: none;
    `
  )}
`;

export const BeeSmall = styled.div`
  transform: translate(100rem, -30rem);
  background: url(${BeeSmallGif}) center/contain no-repeat;
  width: 12rem;
  height: 12rem;
`;

export const BeeSmallPath = styled.div`
  background: url(${BeeSmallPathSvg}) center/contain no-repeat;
  width: 51rem;
  height: 35rem;
  opacity: 0;
`;
