import React, { FunctionComponent } from "react";
import Img from "gatsby-image";
import * as Styled from "./RecipeElement.styled";
import useRecipeImage from "../../useRecipeImage";
import { IngredientType } from "../../../../@types/RecipeType";

type Props = {
  recipe: IngredientType;
};

const RecipeElement: FunctionComponent<Props> = ({ recipe }) => {
  const recipeImage = useRecipeImage(recipe.img);

  return (
    <Styled.Container>
      <Styled.RecipeIcon>
        {recipeImage && (
          <Styled.Image>
            <Img
              fluid={recipeImage.childImageSharp.fluid}
              className={"recipe-image"}
              placeholder="blurred"
              quality={90}
              layout="constrained"
              objectFit="cover"
            />
          </Styled.Image>
        )}
      </Styled.RecipeIcon>
      <Styled.Text>{recipe.line}</Styled.Text>
    </Styled.Container>
  );
};

export default RecipeElement;
