import React, { FunctionComponent } from "react";
import * as Styled from "./CocktailRecipesSection.styled";
import Slider from "./components/Slider";

type Props = {};

const CocktailRecipesSection: FunctionComponent<Props> = (props) => {
  return (
    <Styled.CocktailRecipesSection id={"koktajle"}>
      <Styled.Title>
        Sprawdź zaskakująco proste pomysły na koktajle!
      </Styled.Title>
      <Slider />
    </Styled.CocktailRecipesSection>
  );
};

export default CocktailRecipesSection;
