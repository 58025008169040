import React, { FunctionComponent } from "react";

type Props = {};

const TullamoreIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="112"
      height="96"
      viewBox="0 0 86 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.539 36.9483L63.0747 72.4113H22.1876L1.73175 36.9629L22.1878 1.5H63.0832L83.539 36.9483Z"
        stroke="#305C41"
        fill={"transparent"}
        strokeWidth="2"
        className="frame"
      />
      <g clipPath="url(#clip0_679_7)">
        <path
          d="M53.7281 39.2877L53.8222 39.2334L52.9191 37.6692L49.0453 38.8185L49.0346 38.8L51.963 36.018L51.06 34.4538L50.9648 34.5087C50.9621 34.6103 50.8735 34.7545 50.4849 34.9789C50.3232 35.0722 46.1496 37.4833 45.9885 37.5776C45.5998 37.802 45.4306 37.8066 45.3413 37.7582L45.2462 37.8131L45.9133 38.9685L46.0084 38.9136C46.0111 38.812 46.0997 38.6678 46.4884 38.4434C46.4884 38.4434 50.049 36.3877 50.2106 36.2944L50.2402 36.3457L46.4915 39.9629L46.7642 40.4354L51.774 39L51.8042 39.0523C51.6426 39.1457 48.0847 41.2012 48.0847 41.2012C47.696 41.4256 47.5268 41.4302 47.4375 41.3818C47.3976 41.4048 47.3434 41.4361 47.3434 41.4361L48.0105 42.5915C48.0105 42.5915 48.0657 42.5596 48.1056 42.5366C48.1083 42.435 48.1969 42.2908 48.5856 42.0664C48.7472 41.9731 52.9209 39.562 53.0825 39.4687C53.4701 39.2449 53.6404 39.2397 53.7297 39.2881L53.7281 39.2877Z"
          fill="#0A4635"
        />
        <path
          d="M34.623 37.0564C34.5329 36.9002 34.3341 36.8466 34.1787 36.9363C34.0232 37.0261 33.9703 37.225 34.0604 37.3812C34.1506 37.5373 34.3487 37.5899 34.5042 37.5002C34.6597 37.4104 34.7126 37.2114 34.623 37.0564Z"
          fill="#0A4635"
        />
        <path
          d="M42.5523 32.9246L42.4572 32.9795L43.1806 34.2325L43.2767 34.1769C43.2954 34.0771 43.4061 33.9475 43.8224 33.7852C43.8824 33.7615 44.0071 33.7128 44.1751 33.6473L45.4391 34.3389C45.0052 34.688 44.6615 34.9645 44.5535 35.0515C44.2038 35.3315 44.0372 35.3619 43.9414 35.3282L43.8463 35.3831L44.5691 36.6351L44.6642 36.5801C44.6835 36.4813 44.7079 36.3276 45.0531 36.0325C45.4787 35.6689 49.0811 32.5895 49.0811 32.5895C49.4274 32.306 49.5944 32.274 49.6896 32.3066C49.7295 32.2836 49.7858 32.2511 49.7858 32.2511L48.8608 30.649C48.8608 30.649 48.8035 30.682 48.7636 30.7051C48.7449 30.8049 48.6336 30.9335 48.2149 31.0917C48.2149 31.0917 45.7555 31.9625 44.2727 32.4886C44.1061 32.5451 43.8681 32.4634 43.7277 32.4117C43.6601 32.526 43.4747 32.7672 43.2205 32.8606C42.7948 33.0187 42.6475 32.9572 42.5513 32.9252L42.5523 32.9246ZM48.0851 32.1307L48.1148 32.182L46.2732 33.667L45.3803 33.1788L48.0851 32.1307V32.1307Z"
          fill="#0A4635"
        />
        <path
          d="M37.2902 40.6365C37.1905 40.6338 37.0326 40.547 36.8138 40.168C36.7231 40.0109 36.1473 39.0136 36.1473 39.0136C36.1473 39.0136 36.9544 38.5477 37.8494 38.031L38.4998 39.1576C38.4998 39.1576 38.6128 39.0225 38.8777 38.8696C39.1437 38.7161 39.3161 38.6863 39.3161 38.6863L38.665 37.5587C39.4577 37.101 40.2116 36.6658 40.2116 36.6658L41.1484 38.2885L41.9616 37.819L40.3782 35.0811L40.2851 35.1349C40.2812 35.2344 40.1935 35.3754 39.8109 35.5962C39.7363 35.6393 39.0029 36.0628 38.1535 36.5545C37.987 36.6465 37.7721 36.657 37.591 36.641C37.5143 36.8058 37.3989 36.9861 37.2349 37.0848L37.237 37.0837C36.3104 37.6213 35.4682 38.109 35.3802 38.1598C34.9977 38.3806 34.8307 38.3867 34.7442 38.3408L34.6511 38.3945L36.2322 41.1282L37.3467 40.7272L37.2933 40.6347L37.2902 40.6365Z"
          fill="#0A4635"
        />
        <path
          d="M42.4554 19.7762C42.4526 19.8777 42.3641 20.022 41.9754 20.2464C41.8138 20.3397 38.2098 22.4218 38.2098 22.4218C37.8324 22.6397 37.5015 22.5651 37.3088 22.2313C37.1161 21.8975 37.2169 21.5737 37.5943 21.3558C37.5943 21.3558 41.1983 19.2737 41.3599 19.1804C41.7486 18.956 41.9178 18.9514 42.0065 18.9988L42.1006 18.9444L41.3535 17.6504L41.2584 17.7053C41.2556 17.8069 41.1661 17.9517 40.7784 18.1755C40.6168 18.2688 37.0118 20.3515 37.0118 20.3515C36.8522 20.4436 35.5724 21.2292 36.4404 22.7327C37.3085 24.2362 38.6287 23.5206 38.7893 23.4278C38.7893 23.4278 42.3953 21.3446 42.5559 21.2518C42.9435 21.028 43.1138 21.0229 43.2031 21.0713L43.2972 21.0169L42.5495 19.7219L42.4554 19.7762V19.7762Z"
          fill="#0A4635"
        />
        <path
          d="M29.6724 24.0424C29.4927 23.7312 29.4526 23.5695 29.4643 23.2095L29.4664 23.1754L29.0877 23.1927L29.0847 23.2205C29.0515 23.619 29.0908 23.8003 29.2894 24.1444C29.5598 24.6127 29.8905 24.7792 30.1973 24.602C30.4786 24.4396 30.5288 24.1532 30.3388 23.7959C30.3081 23.7356 30.2748 23.6754 30.241 23.6169C30.2076 23.5568 30.1732 23.4972 30.1419 23.4358C30.1061 23.3716 30.0337 23.2107 30.1207 23.1605C30.2485 23.0867 30.4002 23.347 30.4067 23.3583C30.53 23.5719 30.5904 23.778 30.6006 24.0296L30.6015 24.0619L30.978 24.0431L30.9766 24.0124C30.9681 23.7448 30.9292 23.5098 30.7715 23.2366C30.6553 23.0354 30.3421 22.5945 29.9412 22.826C29.6302 23.0055 29.6701 23.3604 29.8002 23.6071C29.831 23.6674 29.8648 23.7259 29.8981 23.7861C29.9404 23.8616 29.9854 23.9397 30.0248 24.0197C30.1066 24.1874 30.0605 24.2374 30.0185 24.2616C29.8538 24.3566 29.6791 24.0563 29.6714 24.043L29.6724 24.0424Z"
          fill="#0A4635"
        />
        <path
          d="M25.7278 29.2926L21.5132 21.9927L20.6243 22.5059L24.839 29.8058L25.7278 29.2926Z"
          fill="#0A4635"
        />
        <path
          d="M40.1044 26.9009C40.0107 26.7386 39.6982 26.1974 39.6982 26.1974C39.6982 26.1974 43.8228 23.8147 43.9844 23.7214C44.3731 23.497 44.5423 23.4924 44.6316 23.5408C44.6705 23.5184 44.7257 23.4865 44.7257 23.4865L43.978 22.1914L43.8839 22.2457C43.8812 22.3473 43.7926 22.4915 43.4039 22.7159C43.2423 22.8092 39.0686 25.2203 38.907 25.3136C38.5194 25.5374 38.3519 25.5451 38.2616 25.4973L38.1665 25.5522L39.6621 28.138L40.686 27.5469L40.6315 27.4524C40.5301 27.4493 40.3291 27.2901 40.1044 26.9009V26.9009Z"
          fill="#0A4635"
        />
        <path
          d="M35.0507 35.7413L37.8748 34.1108C38.7109 33.6267 39.0253 32.7372 38.4687 31.7755L37.5259 30.1426L37.4328 30.1963C37.4289 30.2958 37.3418 30.4378 36.9592 30.6587C36.7792 30.7626 32.7242 33.1052 32.5263 33.2208C32.1438 33.4417 31.9774 33.4487 31.8909 33.4028L31.7978 33.4565L32.7388 35.0864C33.2671 36.0015 34.2173 36.2347 35.0507 35.7413V35.7413ZM33.4983 34.5288L33.2505 34.0995L37.3583 31.7278L37.6055 32.1561C37.8047 32.5012 37.7743 32.8666 37.333 33.1227C37.0906 33.2627 34.4711 34.775 34.4711 34.775C34.0302 35.0296 33.6975 34.8738 33.4983 34.5288V34.5288Z"
          fill="#0A4635"
        />
        <path
          d="M46.8064 27.3079L46.9005 27.2536L46.1528 25.9585C46.1528 25.9585 46.0986 25.9898 46.0587 26.0128C46.056 26.1144 45.9664 26.2592 45.5787 26.483C45.4171 26.5763 41.2434 28.9874 41.0818 29.0807C40.6942 29.3045 40.5267 29.3122 40.4364 29.2643L40.3413 29.3193L41.837 31.9051L42.8598 31.3146C42.8598 31.3146 42.8278 31.2591 42.8053 31.2201C42.6865 31.201 42.5029 31.0578 42.2782 30.6686C42.1845 30.5063 41.872 29.9651 41.872 29.9651C41.872 29.9651 45.9966 27.5824 46.1582 27.4891C46.5459 27.2652 46.7161 27.2601 46.8054 27.3085L46.8064 27.3079Z"
          fill="#0A4635"
        />
        <path
          d="M33.2883 29.3065C33.7241 29.055 33.8291 28.6369 33.5694 28.1871L33.1265 27.4199L31.7139 28.2355L32.1568 29.0026C32.4088 29.4391 32.8526 29.5581 33.2873 29.3071L33.2883 29.3065ZM32.4852 28.8131L32.2391 28.3869L32.996 27.9499L33.2421 28.3761C33.3879 28.6287 33.3361 28.8366 33.0917 28.9778C32.8472 29.1189 32.6269 29.0585 32.4852 28.8131Z"
          fill="#0A4635"
        />
        <path
          d="M44.6449 62.0584L40.4521 54.7964L39.5633 55.3096L43.756 62.5715L44.6449 62.0584Z"
          fill="#0A4635"
        />
        <path
          d="M37.5153 43.3583L38.1747 44.5004L38.2708 44.4448C38.2908 44.3471 38.3995 44.2213 38.8125 44.065C38.8125 44.065 41.7613 43.0212 42.281 42.8348L42.2917 42.8533C41.8704 43.2102 39.4943 45.2434 39.4943 45.2434C39.1518 45.5219 38.9881 45.5548 38.8934 45.5232C38.8546 45.5456 38.7983 45.5781 38.7983 45.5781L39.4577 46.7202C39.4577 46.7202 39.5139 46.6877 39.5538 46.6646C39.5737 46.5669 39.6831 46.4421 40.0955 46.2848C40.0955 46.2848 44.5102 44.7069 45.0128 44.5262C45.4346 44.3758 45.5766 44.428 45.6702 44.4602L45.7653 44.4053L45.068 43.1976L44.9719 43.2531C44.953 43.3503 44.8438 43.4777 44.4337 43.6391C44.1457 43.752 41.1957 44.9047 41.1957 44.9047L41.1833 44.8831C41.1833 44.8831 43.4042 43.0134 43.8538 42.6347C44.1917 42.3506 44.363 42.3284 44.4576 42.36L44.5538 42.3045L43.8873 41.1501L43.7912 41.2056C43.7707 41.3024 43.6664 41.4407 43.2524 41.5906C42.7023 41.7905 39.9712 42.7812 39.9712 42.7812L39.9587 42.7597C40.9473 41.9657 42.4297 40.7798 42.6725 40.5862C43.0168 40.3108 43.1817 40.2799 43.2763 40.3116L43.3725 40.2561L42.6758 39.0493L42.5796 39.1048C42.5597 39.2026 42.535 39.3511 42.1938 39.6412C41.7876 39.9866 38.215 43.0229 38.215 43.0229C37.8731 43.3024 37.7088 43.3343 37.6157 43.3031L37.5206 43.358L37.5153 43.3583Z"
          fill="#0A4635"
        />
        <path
          d="M47.7076 53.9215C47.6614 53.8674 47.6186 53.8099 47.5763 53.7508C47.541 53.7014 47.504 53.6515 47.4662 53.6049C47.1624 53.2229 46.6767 53.2556 46.3258 53.4581C46.2941 53.4764 46.2621 53.499 46.2348 53.5202L46.203 53.5454L47.0177 54.9565L47.3399 54.7705L46.7262 53.7076C46.9277 53.6597 47.0267 53.7012 47.1071 53.7766C47.1637 53.8275 47.2157 53.8987 47.2764 53.9801C47.303 54.0168 47.3318 54.0549 47.3647 54.0976C47.5416 54.3214 47.8451 54.4761 48.1233 54.3155C48.4577 54.1224 48.3484 53.6542 48.1942 53.3872C48.0193 53.0843 47.8976 52.9751 47.6153 52.8026L47.5757 52.7789L47.3704 53.0823L47.4118 53.1091C47.6313 53.2508 47.743 53.3451 47.8723 53.569C47.9375 53.682 48.0351 53.9337 47.9247 53.9975C47.8306 54.0518 47.7402 53.9615 47.7064 53.9195L47.7076 53.9215Z"
          fill="#0A4635"
        />
        <path
          d="M29.2894 21.5298L29.6178 21.3403L28.8896 20.0791L27.4771 20.8946L28.2236 22.1876L28.5509 21.9987L27.9912 21.0292L28.2295 20.8916L28.5147 21.3856L28.84 21.1978L28.5548 20.7038L28.7481 20.5922L29.2894 21.5298Z"
          fill="#0A4635"
        />
        <path
          d="M31.4531 25.2698L31.7383 25.7638L30.651 26.3916L30.8395 26.7182L31.9268 26.0904L32.2239 26.6049L32.5491 26.4171L31.7783 25.082L31.4531 25.2698Z"
          fill="#0A4635"
        />
        <path
          d="M64.8469 50.3963L41.7144 10.3296L40.8255 10.8428L63.958 50.9094L64.8469 50.3963Z"
          fill="#0A4635"
        />
        <path
          d="M48.519 56.2273C48.5499 56.3258 48.5938 56.4278 48.6478 56.5213C48.8043 56.7924 48.9191 56.911 49.1479 57.0733C48.8547 57.1536 48.5616 56.9531 48.4092 56.6892C48.2924 56.4868 48.2748 56.2957 48.2838 56.0728L48.2854 56.0212L47.9149 56.0243L47.9107 56.0691C47.8977 56.245 47.8994 56.3659 47.9477 56.5489C47.979 56.6692 48.0247 56.7838 48.0852 56.8886C48.352 57.3507 48.9347 57.6538 49.4462 57.3585C49.9576 57.0632 49.9617 56.4419 49.6972 55.9839C49.629 55.8658 49.5523 55.7636 49.4673 55.6825C49.2829 55.5001 48.9764 55.3401 48.7136 55.4919C48.4936 55.6189 48.4211 55.8949 48.5179 56.2279L48.519 56.2273ZM49.2376 55.9863C49.2836 56.0351 49.3281 56.0957 49.3714 56.1706C49.471 56.3432 49.5595 56.5933 49.4408 56.8248C49.2707 56.7026 49.0979 56.5545 48.9686 56.3306C48.9277 56.2597 48.8987 56.1929 48.8794 56.1288C48.8436 56.0029 48.8362 55.8484 48.9026 55.81C48.9824 55.7639 49.1504 55.8942 49.2372 55.9879L49.2376 55.9863Z"
          fill="#0A4635"
        />
        <path
          d="M56.8099 50.8834C56.7339 51.0518 56.6156 51.2366 56.4502 51.3375L56.4522 51.3363C55.5124 51.8817 54.6579 52.3764 54.5689 52.4278C54.1812 52.6516 54.011 52.6568 53.9217 52.6084L53.8276 52.6627L55.4567 55.4796L56.4806 54.8885L56.4254 54.793C56.3241 54.7899 56.1622 54.6985 55.9375 54.3092C55.8444 54.148 55.3593 53.3079 55.3593 53.3079C55.3593 53.3079 56.1776 52.8355 57.0849 52.3117L57.7549 53.4722C57.7549 53.4722 57.8693 53.3349 58.1383 53.1796C58.4074 53.0243 58.5834 52.9938 58.5834 52.9938L57.9134 51.8333C58.7174 51.3691 59.416 50.9658 59.4815 50.928L60.4468 52.6L61.2712 52.124L59.6397 49.303C59.6397 49.303 59.5845 49.3348 59.5456 49.3573C59.5429 49.4588 59.4543 49.6031 59.0656 49.8275C58.9899 49.8712 58.2463 50.3005 57.3847 50.7994C57.2161 50.8925 56.998 50.9021 56.8131 50.8842L56.8099 50.8834Z"
          fill="#0A4635"
        />
        <path
          d="M37.3006 13.0708L37.3516 13.1591C37.5265 13.2211 37.7211 13.3196 37.9799 13.6946C38.0764 13.8334 38.3153 14.2472 38.4488 14.4454C36.307 15.682 34.178 16.9126 34.0839 16.9669C33.6839 17.1978 33.5095 17.2027 33.4169 17.1534L33.3167 17.2113L34.0869 18.5454C34.0869 18.5454 34.1442 18.5123 34.1851 18.4887C34.1887 18.3839 34.2802 18.2352 34.6795 18.0033C34.7736 17.949 36.9053 16.7183 39.0465 15.4806C39.1438 15.6846 39.3978 16.1268 39.4749 16.2863C39.6638 16.6798 39.6583 16.9158 39.6245 17.0983L39.6755 17.1866L40.7382 16.573L38.3638 12.4556L37.301 13.0691L37.3006 13.0708Z"
          fill="#0A4635"
        />
        <path
          d="M52.8474 47.0428C52.7705 47.2118 52.7125 47.3617 52.5471 47.4627C52.1911 47.6682 51.9467 47.8093 51.9098 47.8306C51.5222 48.0544 51.352 48.0596 51.2626 48.0112L51.1706 48.0643L51.9171 49.3573C51.9171 49.3573 51.9713 49.326 52.0102 49.3036C52.0135 49.203 52.1015 49.0578 52.4902 48.8334C52.5423 48.8033 53.018 48.5286 53.6368 48.1714L54.3323 48.5517C53.9199 48.9829 53.3874 49.556 53.111 49.8566C52.8083 50.1875 52.606 50.2057 52.5096 50.1545C52.4698 50.1775 52.4115 50.2112 52.4115 50.2112L53.123 51.4436L55.2517 49.0835C55.4916 49.2627 56.0949 49.547 57.1239 48.9529C57.9616 48.4693 58.763 47.7834 57.869 46.2373L56.9843 44.705L56.8851 44.7623C56.8824 44.8638 56.7938 45.0081 56.4051 45.2325C56.308 45.2886 54.7358 46.1962 53.4783 46.9236C53.3088 47.0174 53.0313 47.0612 52.8464 47.0434L52.8474 47.0428ZM57.0272 46.6808C57.3504 47.2406 57.189 47.5775 56.5646 47.9394C56.1473 48.1803 55.8646 48.286 55.3931 48.0269L54.6198 47.6039C55.7163 46.9708 56.825 46.3306 56.825 46.3306C56.825 46.3306 56.9341 46.5196 57.0278 46.6819L57.0272 46.6808Z"
          fill="#0A4635"
        />
        <path
          d="M40.1513 47.2786C39.9959 47.3684 39.943 47.5673 40.0331 47.7234C40.1232 47.8795 40.3214 47.9322 40.4769 47.8424C40.6323 47.7527 40.6852 47.5537 40.5957 47.3986C40.5061 47.2436 40.3068 47.1888 40.1513 47.2786Z"
          fill="#0A4635"
        />
        <path
          d="M51.8033 46.0783L54.9376 44.2646C55.6986 43.8252 56.0587 42.9655 55.4527 41.9159C54.8461 40.8653 53.9211 40.7489 53.1601 41.1883L50.024 42.9989C49.2636 43.4393 48.9047 44.301 49.5112 45.3517C50.1178 46.4023 51.0428 46.5187 51.8033 46.0783ZM50.3782 44.8525C50.1855 44.5187 50.2857 44.1939 50.6631 43.9759L53.6846 42.2315C54.0615 42.0125 54.3923 42.0872 54.5856 42.422C54.7783 42.7558 54.6775 43.0796 54.3001 43.2975L51.2786 45.042C50.9017 45.2609 50.5709 45.1863 50.3782 44.8525Z"
          fill="#0A4635"
        />
        <path
          d="M37.3182 42.3712C37.4726 42.2821 37.5265 42.0825 37.437 41.9274C37.3475 41.7724 37.1481 41.7177 36.9926 41.8074C36.8372 41.8972 36.7843 42.0961 36.8744 42.2522C36.9645 42.4084 37.1627 42.461 37.3182 42.3712Z"
          fill="#0A4635"
        />
        <path
          d="M45.3529 48.5381L45.1685 48.2188L43.6372 49.1028L43.8216 49.4222L45.3529 48.5381Z"
          fill="#0A4635"
        />
        <path
          d="M46.0031 52.1723C46.0941 52.1198 46.1675 52.0295 46.2072 51.9189C46.2345 51.8456 46.2379 51.7711 46.2331 51.6944C46.3739 51.7446 46.4988 51.7341 46.6215 51.6633C46.9039 51.5003 46.9139 51.1562 46.6465 50.6931C46.3791 50.2299 46.0785 50.0542 45.7972 50.2166C45.6724 50.2887 45.5999 50.3922 45.5692 50.5468C45.4801 50.4942 45.3762 50.4487 45.2506 50.4555C45.1724 50.4596 45.1052 50.4778 45.0428 50.5139C44.5969 50.7713 44.9343 51.4337 45.0481 51.6309C45.162 51.8281 45.5561 52.4304 46.0041 52.1717L46.0031 52.1723ZM46.3089 50.8619C46.4589 51.1218 46.5014 51.304 46.4237 51.3489C46.3173 51.4103 46.1303 51.1855 46.052 51.05C45.9613 50.8928 45.8559 50.6182 45.9623 50.5568C46.042 50.5107 46.1709 50.6253 46.3073 50.8615L46.3089 50.8619ZM45.235 50.8397C45.2442 50.8344 45.2832 50.8311 45.3029 50.832C45.4461 50.8438 45.623 51.0416 45.7256 51.2193C45.7706 51.2973 45.8041 51.367 45.8252 51.4343C45.877 51.5714 45.9083 51.7861 45.8224 51.8357C45.7037 51.9042 45.4804 51.6402 45.3594 51.4307C45.2485 51.2386 45.1164 50.9082 45.234 50.8403L45.235 50.8397Z"
          fill="#0A4635"
        />
        <path
          d="M27.2582 31.0247L27.0708 30.7002L25.6562 31.5169L25.8436 31.8415L27.2582 31.0247Z"
          fill="#0A4635"
        />
        <path
          d="M27.8334 33.6435C27.9356 33.6598 28.0412 33.6372 28.1476 33.5758C28.1946 33.5486 28.2365 33.5149 28.2728 33.4761C28.309 33.4374 28.3371 33.3938 28.3605 33.3447C28.3836 33.2971 28.3967 33.2443 28.4028 33.1888C28.4102 33.1284 28.4058 33.0734 28.3933 33.0258C28.3798 32.9789 28.3565 32.9266 28.3215 32.866L27.7671 31.9058L26.3525 32.7225L26.544 33.0542L27.0994 32.7335L27.3253 33.1248L26.9691 33.7906L27.2057 34.2004L27.5711 33.5197C27.6542 33.5881 27.7426 33.6302 27.8334 33.6435V33.6435ZM27.87 33.2486C27.8357 33.2341 27.8093 33.2096 27.7886 33.1737L27.4263 32.5462L27.6309 32.4281L27.9659 33.0083C27.9997 33.0669 28.0192 33.1172 28.0233 33.1573C28.0245 33.1689 28.0276 33.2027 27.9653 33.2387C27.9325 33.2576 27.9015 33.2605 27.87 33.2486V33.2486Z"
          fill="#0A4635"
        />
        <path
          d="M29.224 34.429L29.0366 34.1045L27.622 34.9212L27.8094 35.2458L29.224 34.429Z"
          fill="#0A4635"
        />
        <path
          d="M29.7449 36.6572C29.7289 36.561 29.6935 36.4595 29.6395 36.3565C29.6107 36.3019 29.5868 36.2582 29.5689 36.2247C29.5499 36.1919 29.5315 36.16 29.5136 36.1266C29.4946 36.0937 29.4723 36.0504 29.4431 35.9975C29.3678 35.8506 29.3611 35.7586 29.4235 35.7226C29.4695 35.696 29.5144 35.7029 29.5653 35.7462C29.6228 35.7938 29.672 35.853 29.7105 35.9197C29.774 36.0296 29.821 36.1394 29.8502 36.2444C29.8797 36.352 29.898 36.4688 29.9024 36.5922L29.9033 36.6246L30.2788 36.6064L30.2784 36.5751C30.2743 36.4214 30.2569 36.2849 30.2272 36.1651C30.1976 36.0452 30.1455 35.922 30.0738 35.7977C30.0133 35.6929 29.9411 35.5963 29.8586 35.5125C29.7743 35.4255 29.6791 35.3669 29.5755 35.3363C29.4687 35.3049 29.3575 35.3225 29.244 35.388C29.1478 35.4436 29.0808 35.5165 29.0438 35.6036C29.0079 35.6901 28.9967 35.7841 29.0098 35.8848C29.0237 35.9821 29.0545 36.078 29.1028 36.1734C29.1283 36.2176 29.1523 36.2639 29.1768 36.3087C29.2029 36.3539 29.2293 36.4044 29.2585 36.4573C29.2878 36.5103 29.3094 36.5525 29.3246 36.5835C29.4181 36.7691 29.3445 36.8117 29.3199 36.8258C29.2616 36.8595 29.2055 36.8522 29.1428 36.8049C29.0744 36.7526 29.0168 36.6859 28.9717 36.6078C28.9125 36.5051 28.8664 36.4112 28.835 36.3308C28.8047 36.2524 28.7832 36.1703 28.7733 36.087C28.7628 36.0027 28.7606 35.8972 28.7641 35.7733L28.7656 35.7382L28.3869 35.7555L28.3839 35.7833C28.3717 35.9204 28.3709 36.0373 28.3782 36.1303C28.3862 36.2243 28.4061 36.3155 28.4384 36.4022C28.4702 36.488 28.52 36.5907 28.5882 36.7088C28.6564 36.8269 28.7355 36.9332 28.8249 37.0267C28.9166 37.1217 29.0196 37.1868 29.1335 37.221C29.2506 37.2561 29.3723 37.2379 29.4971 37.1658C29.6004 37.1062 29.6721 37.0319 29.7106 36.9426C29.7496 36.8544 29.7602 36.7593 29.7434 36.6594L29.7449 36.6572Z"
          fill="#0A4635"
        />
        <path
          d="M30.9377 38.5855L30.541 37.8984L31.0555 37.6014L30.8682 37.2769L29.4536 38.0936L29.6409 38.4181L30.2096 38.0898L30.6063 38.7768L30.0376 39.1052L30.225 39.4297L31.6396 38.613L31.4522 38.2885L30.9377 38.5855Z"
          fill="#0A4635"
        />
        <path
          d="M32.5725 42.3671L33.3529 41.5811L33.1246 41.1857L32.0505 41.4607L32.8145 40.6485L32.6123 40.2983L31.4491 41.5505L31.6815 41.9531L32.7369 41.6669L31.9608 42.4368L32.1962 42.8445L33.867 42.4715L33.6666 42.1244L32.5725 42.3671Z"
          fill="#0A4635"
        />
        <path
          d="M34.2497 44.3228L33.853 43.6357L34.3675 43.3387L34.1802 43.0142L32.7656 43.8309L32.9529 44.1554L33.5216 43.8271L33.9183 44.5141L33.3496 44.8425L33.537 45.167L34.9516 44.3503L34.7642 44.0258L34.2497 44.3228Z"
          fill="#0A4635"
        />
        <path
          d="M35.5716 45.4241L35.3843 45.0996L33.9697 45.9163L34.157 46.2409L35.5716 45.4241Z"
          fill="#0A4635"
        />
        <path
          d="M36.0935 47.6533C36.0775 47.5571 36.0421 47.4556 35.9881 47.3526C35.9593 47.298 35.9354 47.2543 35.9175 47.2208C35.8985 47.188 35.8801 47.1561 35.8622 47.1227C35.8432 47.0898 35.8209 47.0465 35.7917 46.9936C35.7165 46.8467 35.7097 46.7547 35.7721 46.7187C35.8182 46.6921 35.8631 46.699 35.9139 46.7423C35.9714 46.7899 36.0206 46.8491 36.0592 46.9158C36.1226 47.0257 36.1696 47.1355 36.1989 47.2405C36.2283 47.3481 36.2466 47.4649 36.251 47.5883L36.252 47.6207L36.6274 47.6024L36.6271 47.5711C36.6229 47.4175 36.6055 47.281 36.5759 47.1612C36.5462 47.0413 36.4941 46.918 36.4224 46.7938C36.3619 46.689 36.2897 46.5924 36.2073 46.5086C36.123 46.4216 36.0277 46.363 35.9242 46.3324C35.8174 46.3009 35.7062 46.3186 35.5926 46.3841C35.4965 46.4396 35.4295 46.5126 35.3925 46.5997C35.3565 46.6862 35.3453 46.7802 35.3584 46.8809C35.3724 46.9782 35.4032 47.0741 35.4514 47.1695C35.4769 47.2137 35.501 47.26 35.5254 47.3048C35.5515 47.35 35.5779 47.4004 35.6072 47.4534C35.6364 47.5064 35.6581 47.5486 35.6732 47.5796C35.7667 47.7652 35.6931 47.8078 35.6685 47.8219C35.6102 47.8556 35.5542 47.8483 35.4914 47.801C35.4231 47.7487 35.3654 47.682 35.3204 47.6039C35.2611 47.5012 35.215 47.4073 35.1836 47.3269C35.1534 47.2485 35.1319 47.1664 35.122 47.0831C35.1115 46.9988 35.1092 46.8933 35.1127 46.7694L35.1142 46.7343L34.7356 46.7516L34.7325 46.7794C34.7203 46.9165 34.7196 47.0334 34.7269 47.1263C34.7348 47.2204 34.7547 47.3116 34.7871 47.3983C34.8188 47.4841 34.8686 47.5868 34.9368 47.7049C35.005 47.823 35.0841 47.9293 35.1735 48.0228C35.2652 48.1178 35.3683 48.1829 35.4821 48.2171C35.5992 48.2522 35.721 48.234 35.8457 48.1619C35.9491 48.1023 36.0207 48.028 36.0592 47.9387C36.0982 47.8505 36.1088 47.7554 36.0921 47.6555L36.0935 47.6533Z"
          fill="#0A4635"
        />
        <path
          d="M37.9685 49.5753L37.7111 49.1296L36.8807 48.8984L37.4034 48.5966L37.2148 48.27L35.8002 49.0867L35.9888 49.4133L36.4255 49.1612L36.7761 49.2628L36.3612 50.0583L36.5888 50.4527L37.1599 49.363L37.9685 49.5753Z"
          fill="#0A4635"
        />
        <path
          d="M37.6597 50.9987L37.9455 51.4937L38.2708 51.3059L37.985 50.8109L38.1793 50.6987L38.7201 51.6353L39.0484 51.4457L38.3203 50.1846L36.9057 51.0013L37.6522 52.2943L37.9806 52.1047L37.4214 51.1363L37.6597 50.9987Z"
          fill="#0A4635"
        />
        <path
          d="M39.246 52.9759L39.5513 52.345L39.5588 52.3297L39.3223 51.9199L38.8216 53.0087L38.2539 53.3364L38.4413 53.661L39.009 53.3332L40.1957 53.4327L39.968 53.0383L39.246 52.9759Z"
          fill="#0A4635"
        />
      </g>
      <defs>
        <clipPath id="clip0_679_7">
          <rect
            width="52"
            height="35"
            fill="white"
            transform="translate(45.1553 5.2334) rotate(60)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TullamoreIcon;
