import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import {
  AppContainer,
  TextLarge,
  TextNormal,
  TitleLarge,
} from "../../styles/BasicStyles";
import { mediaDown } from "../../styles/Mixins";

export const TasteTheNewSection = styled.section`
  width: 100%;
  padding-top: 15rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 5rem;
    `
  )}
`;

export const Container = styled.div`
  ${AppContainer()};
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  ${TitleLarge};
  text-align: center;
  font-size: 10rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 4.2rem;
    `
  )}
`;

export const Subtitle = styled.p`
  ${TextLarge};
  padding-top: 2.5rem;
  text-align: center;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.8rem;
    `
  )}
`;

export const PerfectServe = styled.div`
  position: relative;
  ${AppContainer()};
  margin-top: 6rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      flex-direction: column;
      align-items: center;
    `
  )}
`;

export const PerfectServeDiv = styled.div`
  position: absolute;
  width: 50%;
  left: 65%;
  top: 25%;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      position: relative;
      width: 100%;
      left: 0;
      top: 0;
    `
  )}
`;

export const PerfectServeTitle = styled.h3`
  ${TextLarge};
  font-size: 4.4rem;
  margin-bottom: 1.5rem;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.6rem;
      text-align: center;
    `
  )}
`;

export const PerfectServeText = styled.p`
  ${TextNormal};
  display: flex;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      text-align: center;
      font-size: 1.8rem;
    `
  )}
`;
