import React, { FunctionComponent } from "react";
import * as Styled from "./SliderElement.styled";
import Img from "gatsby-image";
import useRecipeImage from "../../useRecipeImage";
import RecipeElement from "../ListElement/RecipeElement";
import { RecipeType } from "../../../../@types/RecipeType";

type Props = {
  drink: RecipeType;
};

const SliderElement: FunctionComponent<Props> = ({ drink }) => {
  const recipeImage = useRecipeImage(drink.image);

  return (
    <Styled.Container>
      <Styled.LeftColumn>
        {recipeImage && (
          <Img
            fluid={recipeImage.childImageSharp.fluid}
            className={"recipe-image"}
            placeholder="blurred"
            quality={100}
            layout="constrained"
            objectFit="cover"
          />
        )}
      </Styled.LeftColumn>
      <Styled.RightColumn>
        <Styled.TitleWrapper>
          <svg
            viewBox="0 0 16 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.41214e-05 0V33.497V67L15.9807 33.497L9.41214e-05 0Z"
              fill="#305C41"
            />
          </svg>
          <Styled.Title>{drink.title}</Styled.Title>
        </Styled.TitleWrapper>
        <Styled.Recipe>
          {drink.recipes.map((recipe, index) => (
            <RecipeElement key={index} recipe={recipe} />
          ))}
        </Styled.Recipe>
        <Styled.Description>{drink.description}</Styled.Description>
      </Styled.RightColumn>
    </Styled.Container>
  );
};

export default SliderElement;
