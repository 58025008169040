import {useEffect, useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import {ImageType} from '../../@types/ImageType';

export default function useRecipeImage(imgPath: string) {
    const [image, setImage] = useState<ImageType | undefined>()
    const data: {
        allFile: {
            nodes: ImageType[]
        }
    } = useStaticQuery(graphql`
      query {
          allFile(filter: {relativePath: {regex: "/^CocktailRecipesSection/Slider/"}}) {
            nodes {
              relativePath
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
    `)

    useEffect(() => {
        const images = data.allFile.nodes.filter(node => node.relativePath === imgPath);
        if (images.length < 1) {
            const image = data.allFile.nodes.filter(node => node.relativePath === 'CocktailRecipesSection/Slider/bottle.png')[0];
            setImage(image);
        } else {
            setImage(images[0]);
        }
    }, [data])

    return image
}
