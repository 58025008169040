import * as React from "react";
import { FunctionComponent } from "react";

import MainLayout from "../layouts/MainLayout/MainLayout";

import FloatingButton from "../components/FloatingButton/FloatingButton";
import PrizesSection from "../sections/PrizesSection/PrizesSection";
import RulesSection from "../sections/RulesSection/RulesSection";
import FormSection from "../sections/FormSection/FormSection";
import TasteTheNewSection from "../sections/TasteTheNew/TasteTheNewSection";
import CocktailRecipesSection from "../sections/CocktailRecipesSection/CocktailRecipesSection";
import ContactSection from "../sections/ContactSection/ContactSection";
import Badge from "../components/Badge/Badge";
import { Background } from "../styles/BasicStyles";

const IndexPage: FunctionComponent<{ data: any }> = ({ data }) => (
  <MainLayout>
    <Badge />
    <PrizesSection />
    <RulesSection />
    {/*<FormSection />*/}
    <Background>
      <TasteTheNewSection />
      <CocktailRecipesSection />
      <ContactSection />
    </Background>
    <FloatingButton />
  </MainLayout>
);

export default IndexPage;
