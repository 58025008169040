import styled, { css } from "styled-components";
import { mediaDown } from "../../../styles/Mixins";
import ThemeStyle from "../../../styles/ThemeStyle";

export const Container = styled.section`
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: grab;
  .swiper {
    width: 100%;
    height: auto;
    max-width: 90vw;
    overflow: visible;
    ${mediaDown(
      "2600px",
      css`
        max-width: 70vw;
      `
    )}
    ${mediaDown(
      ThemeStyle.breakpoints.ultra,
      css`
        max-width: 85vw;
        margin-left: 8rem;
      `
    )}
    ${mediaDown(
      ThemeStyle.breakpoints.xxl,
      css`
        max-width: 85vw;
        margin-left: 3rem;
      `
    )}
    ${mediaDown(
      ThemeStyle.breakpoints.lg,
      css`
        margin-left: 3.5rem;
      `
    )}
    ${mediaDown(
      ThemeStyle.breakpoints.sm,
      css`
        max-width: 80vw;
      `
    )}
  }

  .swiper-slide {
    height: auto;
    opacity: 0.5;
    transition: ${ThemeStyle.transition};
    transition-property: opacity;
  }

  .swiper-slide-visible {
    opacity: 1;
  }
`;
