import React, { FunctionComponent } from "react";

type Props = {};

const FacebookIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="112"
      height="96"
      viewBox="0 0 112 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={"frame"}
        d="M109.497 47.9305L82.764 94.2569H29.3511L2.62872 47.9306L29.351 1.62305H82.775L109.497 47.9305Z"
        stroke="#305C41"
        fill={"transparent"}
        strokeWidth="3"
      />
      <path
        className={"icon"}
        d="M51.2768 68.4327H59.4634V47.7611H65.1812L65.7913 40.8419H59.4634V36.8963C59.4634 35.2666 59.7897 34.6233 61.3504 34.6233H65.7771V27.4468H60.1018C54.0009 27.4468 51.2626 30.1487 51.2626 35.3237V40.8419H47.0061V47.8468H51.2626V68.4184L51.2768 68.4327Z"
        fill="#305C41"
      />
    </svg>
  );
};

export default FacebookIcon;
