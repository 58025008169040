import styled, { css } from "styled-components";
import { mediaDown } from "../../styles/Mixins";
import ThemeStyle from "../../styles/ThemeStyle";
import { AppContainer } from "../../styles/BasicStyles";

export const FloatingButton = styled.button`
  transition: ${ThemeStyle.transition};
  display: flex;
  justify-content: center;
  align-items: center;

  svg .frame {
    transition: ${ThemeStyle.transition};
    transition-property: fill, stroke;
  }

  &:hover {
    svg .frame {
      fill: ${ThemeStyle.colors.yellow};
      stroke: ${ThemeStyle.colors.yellow};
    }
  }

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      transform: scale(80%);
    `
  )};
`;

export const Container = styled.div`
  ${AppContainer()};
  margin: 0 auto;
  position: fixed;
  bottom: 10rem;
  right: 5rem;
  z-index: 10000;

  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      right: 2rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      bottom: 2rem;
      right: 0;
      padding: 0;
    `
  )}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
