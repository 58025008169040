import styled, { css } from "styled-components";
import { TitleLarge } from "../../styles/BasicStyles";
import { mediaDown } from "../../styles/Mixins";
import ThemeStyle from "../../styles/ThemeStyle";

export const CocktailRecipesSection = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
`;

export const Title = styled.h2`
  ${TitleLarge};
  font-size: 4.4rem;
  text-align: center;
  padding: 5rem 0 10rem 0;
  max-width: 63rem;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.4rem;
      max-width: 70%;
      padding: 5rem 0;
    `
  )};
`;
