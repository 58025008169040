import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { TextLarge } from "../../styles/BasicStyles";
import { mediaDown } from "../../styles/Mixins";

export const Badge = styled.a`
  position: fixed;
  top: 20rem;
  right: 0;
  padding: 2.5rem 2rem 2.5rem 3rem;
  background-color: ${ThemeStyle.colors.yellow};
  cursor: pointer;
  z-index: 20;
  transition: ${ThemeStyle.transition};
  transition-property: background-color, transform;
  &.toggle {
    transform: translate(100%, 0) !important;
  }
  &:hover {
    background-color: ${ThemeStyle.colors.green};
    p {
      color: ${ThemeStyle.colors.white};
    }
  }
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      padding: 2rem 2rem 2rem 2.5rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      padding: 1rem 1rem 1rem 2rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      top: 35rem;
      &.toggle {
        transform: translate(100%, -400%) !important;
      }
    `
  )}
`;

export const Text = styled.p`
  ${TextLarge};
  font-size: 4rem;
  line-height: 1;
  transition: ${ThemeStyle.transition};
  transition-property: color;
  span {
    display: block;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 3.2rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 1.8rem;
    `
  )}
`;
