import React, { useState, useEffect } from "react";
import * as Styled from "./FloatingButton.styled";

const FloatingButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setIsVisible(true);
    } else if (scrolled <= 300) {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    if (!window) {
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!window) {
      return;
    }
    window.addEventListener("scroll", toggleVisible);
  }, []);

  return (
    <>
      {isVisible && (
        <Styled.Container>
          <Styled.Wrapper>
            <Styled.FloatingButton >
              <svg
                width="70"
                height="61"
                viewBox="0 0 70 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={scrollToTop}
              >
                <path
                    className={"frame"}
                    fill={"#FAF4E2"}
                    d="M67.9641 30.1996L51.3958 58.9112H18.2932L1.73175 30.2115L18.2934 1.5H51.4027L67.9641 30.1996Z"
                  stroke="#305C41"
                  strokeWidth="3"
                />
                <path
                    className={"icon"}

                    d="M35.9086 17.3731C35.3228 16.7873 34.373 16.7873 33.7872 17.3731L24.2413 26.919C23.6555 27.5048 23.6555 28.4546 24.2413 29.0404C24.8271 29.6261 25.7768 29.6261 26.3626 29.0404L34.8479 20.5551L43.3332 29.0404C43.919 29.6261 44.8687 29.6261 45.4545 29.0404C46.0403 28.4546 46.0403 27.5048 45.4545 26.919L35.9086 17.3731ZM36.3479 43.9224L36.3479 18.4338L33.3479 18.4338L33.3479 43.9224L36.3479 43.9224Z"
                  fill="#305C41"
                />
              </svg>
            </Styled.FloatingButton>
          </Styled.Wrapper>
        </Styled.Container>
      )}
    </>
  );
};

export default FloatingButton;
