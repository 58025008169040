import React, { FunctionComponent, useEffect, useRef } from "react";
import * as Styled from "./RulesSection.styled";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const RulesSection: FunctionComponent = () => {
  const container = useRef(null);
  const smallBeeWrapper = useRef(null);
  const largeBeeWrapper = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const containerCurrent = container.current;
    const smallBeeCurrent = smallBeeWrapper.current;
    const largeBeeCurrent = largeBeeWrapper.current;
    if (!containerCurrent || !smallBeeCurrent || !largeBeeCurrent) {
      return;
    }
    const beeLarge = largeBeeCurrent.children[0];
    const beeLargePath = largeBeeCurrent.children[1];
    const beeSmall = smallBeeCurrent.children[0];
    const beeSmallPath = smallBeeCurrent.children[1];
    const titleText = containerCurrent.children[2];
    const textWrapper = containerCurrent.children[3];

    gsap.to(beeSmall, {
      x: "-=1000",
      y: "+=300",
      duration: 0.5,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: containerCurrent,
        start: "5% bottom",
        end: "40% bottom",
        scrub: true,
        pin: false,
      },
    });
    gsap.to(beeSmallPath, {
      y: "-=100",
      opacity: 1,
      autoAlpha: 1,
      delay: 10,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: containerCurrent,
        start: "10% bottom",
        end: "40% bottom",
        scrub: true,
        pin: false,
      },
    });

    gsap.to(titleText, {
      y: "-=100",
      opacity: 1,
      autoAlpha: 1,
      duration: 0.5,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: containerCurrent,
        start: "0 bottom",
        end: "30% bottom",
        pin: false,
      },
    });
    gsap.to(textWrapper, {
      y: "-=100",
      opacity: 1,
      autoAlpha: 1,
      duration: 0.5,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: containerCurrent,
        start: "5% bottom",
        end: "30% bottom",
        pin: false,
      },
    });

    gsap.to(beeLarge, {
      x: "+=1000",
      y: "-=300",
      duration: 2,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: containerCurrent,
        start: "30% bottom",
        end: "70% bottom",
        scrub: true,
        pin: false,
      },
    });
    gsap.to(beeLargePath, {
      y: "-=50",
      opacity: 1,
      autoAlpha: 1,
      duration: 2,
      delay: 10,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: containerCurrent,
        start: "60% bottom",
        end: "70% bottom",
        scrub: true,
        pin: false,
      },
    });
  });

  return (
    <Styled.RulesSection id={"wez-udzial"}>
      <Styled.Container ref={container}>
        <Styled.BeeLargeWrapper ref={largeBeeWrapper}>
          <Styled.BeeLarge />
          <Styled.BeeLargePath />
        </Styled.BeeLargeWrapper>
        <Styled.BeeSmallWrapper ref={smallBeeWrapper}>
          <Styled.BeeSmall />
          <Styled.BeeSmallPath />
        </Styled.BeeSmallWrapper>
        <Styled.TitleYellow>Weź udział!</Styled.TitleYellow>
        <Styled.Wrapper>
          <Styled.TitleWhite>1. Kup</Styled.TitleWhite>
          <Styled.Subtitle>
            Tullamore D.E.W. Honey i zachowaj paragon.
          </Styled.Subtitle>
          <Styled.TitleWhite>2. Opowiedz nam</Styled.TitleWhite>
          <Styled.Subtitle>
            o swoim najbardziej ZASKAkUJĄCYM BEEforze w towarzystwie przyjaciół!
          </Styled.Subtitle>
          <Styled.SubtitleSmall>
            Nagrodzimy najbardziej kreatywne odpowiedzi!
            <br />
            Konkurs trwa w dniach 15.07.2022 - 15.08.2022.
          </Styled.SubtitleSmall>
        </Styled.Wrapper>
      </Styled.Container>
    </Styled.RulesSection>
  );
};

export default RulesSection;
