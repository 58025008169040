import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { AppContainer, TextLarge, TextNormal } from "../../styles/BasicStyles";
import { IconWrapper } from "../../components/Icon/IconWrapper.styled";
import { mediaDown, Transition } from "../../styles/Mixins";
import BeeLargeGif from "../../images/Common/bee-large.gif";

export const ContactSection = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const Container = styled.div`
  ${AppContainer()};
  flex-direction: column;
  align-items: center;
  padding-top: 14rem;
  padding-bottom: 46rem;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      padding-top: 14rem;
      padding-bottom: 30rem;
    `
  )};
`;

export const Header = styled.h2`
  ${TextLarge};
  text-align: center;
  margin-bottom: 2.5rem;
`;

export const Sub = styled.p`
  ${TextNormal};
  text-align: center;
  margin-bottom: 7rem;
`;

export const ContactIconsWrapper = styled(IconWrapper)`
  position: relative;
  transform: translateX(-25%) rotate(-30deg);
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      margin: 0 0 7.5rem 0;
      transform: translateX(-25%) rotate(-30deg) scale(70%);
    `
  )};
`;

export const SingleIconWrapper = styled.a`
  display: block;
  transform: rotate(30deg);
  svg path {
    ${Transition("all", 0.2)};
    transition-property: fill, stroke;
  }
  &:hover {
    svg .frame {
      fill: ${ThemeStyle.colors.yellow};
    }
  }
`;

export const TullamoreIconWrapper = styled(SingleIconWrapper)`
  position: absolute;
  transform: rotate(-30deg);
  left: 4.7rem;
  top: 8rem;
  &:hover {
    z-index: 2;
  }
`;

export const BeeLarge = styled.div`
  position: absolute;
  top: 35%;
  left: 10%;
  transform: translate(-70rem, 0);
  background: url(${BeeLargeGif}) center/contain no-repeat;
  width: 34rem;
  height: 34rem;
  opacity: 0;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      width: 27rem;
      height: 27rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.xl,
    css`
      width: 22rem;
      height: 22rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      width: 15rem;
      height: 15rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      top: 55%;
      transform: translate(-30rem, 0);
      width: 10rem;
      height: 10rem;
    `
  )}
`;
