import React, { FunctionComponent, useEffect, useRef } from "react";
import * as Styled from "./Badge.styled";
import scrollToElement from "../../functions/scrollToElement";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

type Props = {};

const Badge: FunctionComponent<Props> = (props) => {
  const badge = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const badgeCurrent = badge.current;

    if (window.innerWidth < 576) {
      gsap.to(badgeCurrent, {
        yPercent: "200",
        scrollTrigger: {
          trigger: "#landscape",
          start: "top top",
          end: "bottom 85%",
          scrub: 0,
          pin: false,
        },
      });
    }
    gsap.to(badgeCurrent, {
      scrollTrigger: {
        trigger: "#wez-udzial",
        start: "top bottom",
        end: "bottom 10%",
        onEnter: () => {
          badgeCurrent.classList.add("toggle");
        },
        onLeave: () => {
          badgeCurrent.classList.remove("toggle");
        },
        onLeaveBack: () => {
          badgeCurrent.classList.remove("toggle");
        },
        onEnterBack: () => {
          badgeCurrent.classList.add("toggle");
        },
      },
    });
  });

  return (
    <Styled.Badge
      id={"badge"}
      ref={badge}
      onClick={(e) => scrollToElement(e, "wez-udzial")}
    >
      <Styled.Text>
        Weź udział <span>w konkursie!</span>
      </Styled.Text>
    </Styled.Badge>
  );
};

export default Badge;
