import React, { FunctionComponent, useEffect, useRef } from "react";
import * as Styled from "./PrizesSection.styled";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";

const PrizesSection: FunctionComponent = () => {
  const container = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const containerCurrent = container.current;
    const bee = containerCurrent.children[0];
    const title = containerCurrent.children[1];
    const textColumn = containerCurrent.children[2];
    const titleYellow = containerCurrent.children[3];
    const subtitle = containerCurrent.children[4];
    const beeforBox = containerCurrent.children[5];
    const note = containerCurrent.children[6];

    const elements = [title, textColumn, titleYellow, subtitle, beeforBox];

    elements.forEach((el) => {
      gsap.to(el, {
        y: "-=100",
        opacity: 1,
        autoAlpha: 1,
        duration: 0.3,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: el,
        },
      });
    });

    gsap.to(bee, {
      x: "+=1100",
      y: "-=300",
      opacity: 1,
      autoAlpha: 1,
      scrollTrigger: {
        trigger: containerCurrent,
        start: "30% bottom",
        end: "60% bottom",
        scrub: true,
        pin: false,
      },
    });

    gsap.to(note, {
      y: "-=100",
      opacity: 1,
      autoAlpha: 1,
      duration: 0.3,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: beeforBox,
        start: "50% bottom",
      },
    });
  });

  return (
    <Styled.PrizesSection id={"konkurs"}>
      <Styled.Container ref={container}>
        <Styled.BeeLarge />
        <Styled.Title>Konkurs</Styled.Title>
        <Styled.TextColumn>
          <Styled.Text>
            Zaskakujące zderzenia to nasza specjalność! Uwielbiamy nieoczywiste
            połączenia, które łączą ludzi w&nbsp;najbardziej niespodziewanych
            momentach.
          </Styled.Text>
          <Styled.Text>
            Graj o&nbsp;unikatowy BEEfor box i&nbsp;zaproś przyjaciół na
            zaskakującą imprezę w&nbsp;towarzystwie naszej nowej Tullamore
            D.E.W. Honey!{" "}
          </Styled.Text>
        </Styled.TextColumn>
        <Styled.TitleYellow id={"nagrody"}>Nagrody</Styled.TitleYellow>
        <Styled.Subtitle>
          Graj o&nbsp;unikatowy BEEfor BOX z&nbsp;Tullamore D.E.W. Honey
          i&nbsp;o&nbsp;podwójną wejściówkę na ON AIR Festival!{" "}
        </Styled.Subtitle>
        <Styled.Box>
          <StaticImage
            src={"../../images/PrizesSection/beefor-box.png"}
            alt={"Beefor box"}
          />
          <Styled.Info>Wizualizacja nagród ma charakter poglądowy.</Styled.Info>
        </Styled.Box>
        <Styled.Note>
          <Styled.NoteTitle>
            Zrób b(u)zzz z&nbsp;przyjaciółmi!{" "}
          </Styled.NoteTitle>
          <Styled.NoteText>
            Do zdobycia 10x BEEfor box z&nbsp;podwójnym biletem na ON AIR
            Festival oraz 50x Tullamore&nbsp;D.E.W&nbsp;Honey!<br></br>
            Szczegóły znajdziesz w
            <Styled.Link
              href="/Regulamin-konkursu-BEEfor-z-charakterem.pdf"
              target={"_blank"}
            >
              REGULAMINIE
            </Styled.Link>
          </Styled.NoteText>
        </Styled.Note>
      </Styled.Container>
    </Styled.PrizesSection>
  );
};

export default PrizesSection;
