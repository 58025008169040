import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import {
  AppContainer,
  LinkBase,
  TextLarge,
  TextNormal,
  TitleColored,
  TitleLarge,
} from "../../styles/BasicStyles";
import Background from "../../images/Common/bg-light.jpg";
import { mediaDown } from "../../styles/Mixins";
import BeeLargeGif from "../../images/Common/bee-large.gif";
import BeeLargeGifMobile from "../../images/Common/bee-large-mobile.gif";

export const PrizesSection = styled.section`
  width: 100%;
  background: url("${Background}") center/cover no-repeat;
  overflow: hidden;
`;

export const Container = styled.div`
  ${AppContainer()};
  position: relative;
  flex-flow: column;
  align-items: center;
  padding-top: 50rem;
  padding-bottom: 10rem;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      padding-top: 30rem;
      padding-bottom: 10rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 7rem;
      padding-bottom: 7rem;
    `
  )};
`;

export const Title = styled.h2`
  ${TitleLarge};
  font-size: 10rem;
  margin-bottom: 7rem;
  transform: translateY(10rem);
  opacity: 0;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      margin-bottom: 3rem;
    `
  )};
`;

export const TextColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 9.5rem;
  transform: translateY(10rem);
  opacity: 0;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 1fr;
      gap: 3rem;
    `
  )};
`;

export const Text = styled.h3`
  ${TextNormal};
  max-width: 33rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      text-align: center;
    `
  )};
`;

export const TitleYellow = styled.h2`
  ${TitleColored("yellow")};
  margin-bottom: 6rem;
  transform: translateY(10rem);
  opacity: 0;
`;

export const Subtitle = styled.h3`
  ${TextLarge};
  max-width: 60rem;
  line-height: 1.3;
  text-align: center;
  transform: translateY(10rem);
  opacity: 0;
`;

export const Box = styled.div`
  margin: 4rem 0;
  max-width: 43rem;
  transform: translateY(10rem);
  opacity: 0;
`;

export const Info = styled.p`
  ${TextNormal};
  font-size: 1.2rem;
  transform: translate(2rem, -3.5rem);
  color: rgba(48, 92, 65, 0.5);
  text-align: center;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      transform: translate(2rem, -2.5rem);
    `
  )};
`;

export const Note = styled.div`
  display: flex;
  flex-flow: column;
  transform: translateY(10rem);
  opacity: 0;
`;

export const NoteTitle = styled.h4`
  ${TextLarge};
  text-align: center;
  margin-bottom: 2rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      margin-bottom: 1rem;
    `
  )};
`;

export const NoteText = styled.p`
  ${TextNormal};
  text-align: center;
  display: inline-block;
`;

export const Link = styled(LinkBase)`
  font-size: 2.2rem;
  display: inline;
  margin: 0 0 0 0.4rem;
`;

export const BeeLarge = styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  transform: translate(-100rem, 30rem);
  background: url(${BeeLargeGif}) center/contain no-repeat;
  width: 30rem;
  height: 30rem;
  opacity: 0;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      width: 20rem;
      height: 20rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      width: 15rem;
      height: 15rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      background: url(${BeeLargeGifMobile}) center/contain no-repeat;
      top: 0;
      left: -9rem;
      width: 10rem;
      height: 10rem;
    `
  )}
`;
