import React, { FunctionComponent, useEffect, useRef } from "react";
import * as Styled from "./TasteTheNewSection.styled";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

type Props = {};

const TasteTheNewSection: FunctionComponent<Props> = (props) => {
  return (
    <Styled.TasteTheNewSection>
      <Styled.Container>
        <Styled.Title>Zasmakuj nowości</Styled.Title>
        <Styled.Subtitle>TULLAMORE D.E.W. HONEY</Styled.Subtitle>
        <Styled.PerfectServe>
          <StaticImage
            src="../../images/TasteTheNew/bottle.png"
            alt="Butelka Tullamore DEW Honey ze szklanką"
          />
          <Styled.PerfectServeDiv>
            <Styled.PerfectServeTitle>
              Propozycja podania
            </Styled.PerfectServeTitle>
            <Styled.PerfectServeText>
              Mniej znaczy więcej - żeby wydobyć charakter i&nbsp;słodycz
              Tullamore&nbsp;D.E.W. Honey nie&nbsp;trzeba wiele. Spróbuj wersji
              na&nbsp;lodzie -&nbsp;Honey on the rocks!
            </Styled.PerfectServeText>
          </Styled.PerfectServeDiv>
        </Styled.PerfectServe>
      </Styled.Container>
    </Styled.TasteTheNewSection>
  );
};

export default TasteTheNewSection;
