import React, { FunctionComponent, useEffect, useRef } from "react";
import * as Styled from "./ContactSection.styled";
import FacebookIcon from "./Icons/FacebookIcon";
import TullamoreIcon from "./Icons/TullamoreIcon";
import { BeeLarge } from "./ContactSection.styled";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

type Props = {};

const ContactSection: FunctionComponent<Props> = (props) => {
  const bee = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const containerCurrent = container.current;
    const beeGif = bee.current;

    if (window.innerWidth > 576) {
      gsap.to(beeGif, {
        opacity: 1,
        autoAlpha: 1,
        x: "+=700",
        scrollTrigger: {
          trigger: containerCurrent,
          start: "top bottom",
          end: "50% bottom",
          scrub: true,
        },
      });
    } else {
      gsap.to(beeGif, {
        opacity: 1,
        autoAlpha: 1,
        x: "+=300",
        scrollTrigger: {
          trigger: containerCurrent,
          start: "top bottom",
          end: "30% bottom",
          scrub: true,
        },
      });
    }
  });

  return (
    <Styled.ContactSection id={"kontakt"} ref={container}>
      <BeeLarge ref={bee} />
      <Styled.Container>
        <Styled.Header>Kontakt</Styled.Header>
        <Styled.Sub>
          Obserwuj nas i bądź na bieżąco ze światem Tullamore D.E.W.!
        </Styled.Sub>
        <Styled.ContactIconsWrapper>
          <Styled.SingleIconWrapper
            target="_blank"
            href="https://www.facebook.com/TullamoreDewPolska"
          >
            <FacebookIcon />
          </Styled.SingleIconWrapper>
          <Styled.TullamoreIconWrapper
            target="_blank"
            href="https://www.tullamoredew.com/pl-pl/"
          >
            <TullamoreIcon />
          </Styled.TullamoreIconWrapper>
        </Styled.ContactIconsWrapper>
      </Styled.Container>
    </Styled.ContactSection>
  );
};

export default ContactSection;
