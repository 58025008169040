import styled from "styled-components";

export const IconWrapper = styled.div``;

export const Icon = styled.div`
  display: flex;
  margin: auto;
  height: 75%;

  svg {
    margin: auto;
    width: 100%;
  }
`;
